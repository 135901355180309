import React, { FC } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Box, Paragraph } from "grommet";
import { useSize, useTheme } from "../../Hooks";

export interface IDescription {
  description: string | string[];
  offset: number;
}

interface IBulletPoints {
  description: string[];
}

const Bullet = () => {
  const color = useTheme("dark-1");
  return (
    <Box
      as="span"
      fill={false}
      style={{ display: "inline", color, fontSize: "30px" }}
      margin={{ right: "small" }}
    >
      &#8226;
    </Box>
  );
};

const BulletPoints: FC<IBulletPoints> = ({ description }) => {
  return (
    <Box as="ul" pad="none">
      {description.map((item) => (
        <Box as="li" key={item} direction="row">
          <Bullet />
          <Paragraph margin="xsmall" size="small" fill color="dark-3">
            {item}
          </Paragraph>
        </Box>
      ))}
    </Box>
  );
};

const Description: FC<IDescription> = ({ description, offset }) => {
  const size = useSize();
  return (
    <ParallaxLayer offset={offset + 0.4} speed={1.5}>
      <Box margin={size}>
        {Array.isArray(description) ? (
          <BulletPoints {...{ description }} />
        ) : (
          <Paragraph margin="none" size="small" color="dark-3">
            {description}{" "}
          </Paragraph>
        )}
      </Box>
    </ParallaxLayer>
  );
};

export { Description };
