import React, { FC } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Anchor, Box, Text } from "grommet";

export interface IHeader {
  companyName: string;
  companyUrl: string;
  position: string;
  dates: string;
  location: string;
  offset: number;
}

const Header: FC<IHeader> = ({
  companyName,
  companyUrl,
  position,
  location,
  dates,
  offset,
}) => {
  return (
    <ParallaxLayer offset={offset + 0.2} speed={0} style={{ zIndex: 3 }}>
      <Box
        direction="row"
        gap="small"
        align="center"
        margin={{ left: "medium", top: 'xlarge' }}
      >
        <Anchor
          size="large"
          href={companyUrl}
          label={companyName}
          target="_blank"
          color="dark-2"
        />
        <Text size="small" weight="bold" margin={{ top: "5px" }} color="dark-1">
          {position}
        </Text>
      </Box>
      <Box
        direction="row"
        gap="small"
        align="center"
        margin={{ left: "large" }}
      >
        <Text size="xsmall" color="dark-3">{dates}</Text>
        <Text size="small" color="dark-3">{location}</Text>
      </Box>
    </ParallaxLayer>
  );
};

export { Header };
