import React, { FC } from "react";
import { Image, Box } from "grommet";
import { Description, Header, IDescription, IHeader } from "./";
import Tent from "../../assets/tent.png";
import Btc from "../../assets/btc.png";
import Dna from "../../assets/dna.png";
import { ParallaxLayer } from "@react-spring/parallax";

const images: any = {
  tent: Tent,
  btc: Btc,
  dna: Dna,
};

type IExperience = {
  bg: string;
  img: string;
} & IHeader &
  IDescription;

export const Experience: FC<IExperience> = ({
  offset,
  bg,
  img,
  description,
  ...rest
}) => {
  return (
    <Box>
      <ParallaxLayer
        offset={offset}
        speed={2}
        style={{
          display: "flex",
          // opacity: 0.4
        }}
      >
        <Image
          opacity="weak"
          style={{
            width: "100%",
            objectFit: "scale-down",
          }}
          src={images[img]}
        />
      </ParallaxLayer>
      <Header {...rest} {...{ offset }} />
      <Description {...{ description, offset }} />
    </Box>
  );
};
