import React, { FC } from "react";
import {Box, Heading, Paragraph } from "grommet";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Data from "./data.json";
import { Experience } from "./modules/experience";

const App: FC<any> = () => {
  return (
    <Box justify="center" align="center" background="background">
      <Parallax pages={Data.experience.length + 1} style={{ top: "0", left: "0" }}>
        <ParallaxLayer
          offset={0}
          speed={2.5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box justify="center" align="center">
            <Heading margin="none" color="dark-3">Felipe Loyola</Heading>
            <Paragraph color="dark-1">Software Engineer</Paragraph>
          </Box>
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 1, end: 3 }}
          style={{ zIndex: 1, height: 50 }}
        >
          <Box align="center">
            <Heading color="dark-3">Experience</Heading>
          </Box>
        </ParallaxLayer>
        {Data.experience.map((experience, index) => (
          <Experience {...experience} offset={index + 1} />
        ))}
      </Parallax>
    </Box>
  );
};

export default App;
