import React, { useContext } from "react";
import { ResponsiveContext, ThemeContext } from "grommet";
import { normalizeColor } from "grommet/utils";

export const useTheme = (path: string) => {
  const theme = useContext(ThemeContext);
  return normalizeColor(path, theme);
};

export const useSize = () => {
  const size = React.useContext(ResponsiveContext);
  return size;
};
